* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins";
  font-weight: 400;
  /* font-style: normal; */
}
