.content {
  display: flex;
  min-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 50px;
}
.content img {
  width: 300px;
}
h1 {
  font-weight: 600;
  font-size: 25px;
}
p {
  margin-top: 10px;
  line-height: 1.6;
  font-size: 13px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  margin-bottom: 5px;
}
.logo {
  margin-top: 30px;
  width: 180px !important;
}
i {
  padding: 5px;
  margin-right: 5px;
}
